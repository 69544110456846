/**
 * ucwords capitalizes first character of each word of a string
 * @param  {string} value
 * @return {string}
 */
export const ucwords = value => {
  if (!value) return ""
  return value
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

/**
 * ucwords capitalizes first character of a string
 * @param  {string} value
 * @return {string}
 */
export const ucfirst = value => {
  if (!value) return ""
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * striptags strips html tags from the string
 * @param  {string} html
 * @return {string}
 */
export const striptags = html => {
  if (!html) return ""
  const temp = document.createElement("div")
  temp.innerHTML = html
  return temp.textContent || temp.innerText || ""
}

/**
 * truncate returns a truncated version of a string
 * @param  {string} value
 * @param  {number} length
 * @return {string}
 */
export const truncate = (value, length) => {
  if (value) {
    if (value.length <= length) {
      return value
    } else {
      return value.slice(0, length) + "..."
    }
  }
  return ""
}

/**
 * calculateCharacterCount returns the number of characters in a text
 * @param  {string} text
 * @param  {boolean} assumeEnterAsManyCharacters
 * @return {number}
 */
export const calculateCharacterCount = (text, assumeEnterAsManyCharacters) => {
  if (!text || text.trim() === "") {
    return 0
  }
  if (assumeEnterAsManyCharacters) {
    const countOfEnter = text.split(/\r\n|\r|\n/).length - 1
    return text.replace(/(\r\n|\n|\r)/gm, "").length + countOfEnter * 40
  }
  return text.length
}